@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-gradient-custom {
    background-image: linear-gradient(to bottom, #bed4eb, #f6f9fd);
  }
}

@font-face {
  font-family: "Samsung Sharp Sans";
  src: url("./fonts/SAMSUNGSHARPSANS-REGULAR_SMCPS.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Samsung Sharp Sans";
  src: url("./fonts/SAMSUNGSHARPSANS-MEDIUM_SMCP.TTF") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Samsung Sharp Sans";
  src: url("./fonts/SAMSUNGSHARPSANS-BOLD.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Samsung Sharp Sans";
  src: url("./fonts/SAMSUNGSHARPSANS-BOLD_SMCPS.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-variant: small-caps;
}
